import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-secondary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "schools-guiding-statements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#schools-guiding-statements",
        "aria-label": "schools guiding statements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`School’s Guiding Statements`}</h2>
    <p><strong parentName="p">{`Mission`}</strong></p>
    <p>{`Pechersk School International is a diverse `}<strong parentName="p">{`learning community`}</strong>{` which provides an `}<strong parentName="p">{`academically challenging programme`}</strong>{` in a `}<strong parentName="p">{`student-centred environment`}</strong>{`. As an IB world school, PSI is committed to developing well-rounded, `}<strong parentName="p">{`global citizens`}</strong>{` by promoting `}<strong parentName="p">{`personal excellence`}</strong>{` in the classroom and beyond.`}</p>
    <Table fullWidth innerWidth={1000} mdxType="Table">
      <table>
    <colgroup>
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "33%"
          }} />
        <col style={{
            "width": "49%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <td>
            <strong>OUR MISSION</strong>
        </td>
        <td>
            <strong>DEFINITION</strong>
        </td>
        <td>
            <strong>LOOKS LIKE….</strong>
        </td>
    </tr>
    </thead>
    <tr className="odd">
        <td>
            <strong>Learning Community</strong>
        </td>
        <td>A spirit of collaboration pervades the school, with all members of the school community committed to student success and well-being as well as to their own lifelong learning.
        </td>
        <td>
            <ul>
                <li>
                    <p>Students, teachers, staff, and families working together to ensure student success</p>
                </li>
                <li>
                    <p>Respectful and positive interactions among all shareholders</p>
                </li>
                <li>
                    <p>A focus on learning for all members of the community</p>
                </li>
            </ul>
        </td>
    </tr>
    <tr className="header">
        <td>
            <strong>Academically challenging programme</strong>
        </td>
        <td>PSI is an IB World School offering all three programmes for students EC - Grade 12. Students are prepared to attend universities that match their needs and abilities.
        </td>
        <td>
            <ul>
                <li>
                    <p>Written, taught and assessed curriculum standards based on current best practices</p>
                </li>
                <li>
                    <p>An IB curriculum framework</p>
                </li>
                <li>
                    <p>Frequent internal and external, formative and summative assessment</p>
                </li>
                <li>
                    <p>Mother tongue programme</p>
                </li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td>
            <p>
                <strong>Student-</strong>
            </p>
            <p>
                <strong>centered environment</strong>
            </p>
        </td>
        <td>Students engage in a wide variety of activities and experiences that promote powerful learning along with increased self-confidence, personal empowerment, and the freedom to explore personal passions.
        </td>
        <td>
            <ul>
                <li>
                    <p>An IB inquiry approach with the students at the center of the learning</p>
                </li>
                <li>
                    <p>Extensive libraries and IT resources that support a range of topics and interests</p>
                </li>
                <li>
                    <p>A Secondary School Bring Your Own Device programme to connect students to a wider world</p>
                </li>
                <li>
                    <p>Support for student-initiated action</p>
                </li>
            </ul>
        </td>
    </tr>
    <tr className="header">
        <td>
            <strong>Global citizens</strong>
        </td>
        <td>PSI students and community members are global citizens, empowered to recognise and act on issues of importance in their local and international communities.
        </td>
        <td>
            <ul>
                <li>
                    <p>Appreciate local and international cultures</p>
                </li>
                <li>
                    <p>Engage positively with students within and beyond their own nationality/background</p>
                </li>
                <li>
                    <p>Accept and understand their own and other cultures</p>
                </li>
                <li>
                    <p>Listen to others and respect opinions/perspectives</p>
                </li>
                <li>
                    <p>Express opinions respectfully</p>
                </li>
                <li>
                    <p>Take positive action on issues of local, national and global importance</p>
                </li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td>
            <strong>OUR MISSION</strong>
        </td>
        <td>
            <strong>DEFINITION</strong>
        </td>
        <td>
            <strong>LOOKS LIKE….</strong>
        </td>
    </tr>
    <tr className="header">
        <td>
            <strong>Personal excellence</strong>
        </td>
        <td>At PSI, students work with teachers to define their own standards for excellence both in academics and areas of passion, and work to achieve those
        </td>
        <td>
            <ul>
                <li>
                    <p>Student goal-setting</p>
                </li>
                <li>
                    <p>Student-led conferences and portfolios</p>
                </li>
                <li>
                    <p>Student-Parent-Teacher Conferences</p>
                </li>
                <li>
                    <p>Standards based reporting</p>
                </li>
                <li>
                    <p>A variety of external and internal assessments, as well as self-assessment and reflection</p>
                </li>
                <li>
                    <p>Recognising high achievement as well as personal effort</p>
                </li>
                <li>
                    <p>Ukrainian Externum Programme</p>
                </li>
                <li>
                    <p>Academic Integrity Policy</p>
                </li>
                <li>
                    <p>PYP Exhibition, MYP Personal Projects, DP Extended Essay</p>
                </li>
                <li>
                    <p>Sports competitions, theatrical and musical performances, Model United Nations, Maths Counts, Destination Imagination, and other opportunities that showcase student talent</p>
                </li>
                <li>
                    <p>Placements at top rate universities around the world</p>
                </li>
            </ul>
        </td>
    </tr>
      </table>
    </Table>
    <h3 {...{
      "id": "vision",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#vision",
        "aria-label": "vision permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VISION`}</h3>
    <p>{`We grow. We lead. We succeed.`}</p>
    <h3 {...{
      "id": "school-motto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#school-motto",
        "aria-label": "school motto permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SCHOOL MOTTO`}</h3>
    <p>{`‘PSI…a place where we belong.’`}</p>
    <h3 {...{
      "id": "core-values",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#core-values",
        "aria-label": "core values permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CORE VALUES`}</h3>
    <p><strong parentName="p">{`Integrity, Honesty, Trust:`}</strong>{` We value acting on strong moral principles that build trust within our community.`}</p>
    <p><strong parentName="p">{`Respect, Empathy, Tolerance:`}</strong>{` We value respect and understanding for each other’s individualities, cultures, beliefs, ideas and needs.`}</p>
    <p><strong parentName="p">{`Diversity, Equity, Inclusion:`}</strong>{` We value a diverse community where everyone is safe, included, appreciated, and feels free to be themselves.`}</p>
    <p><strong parentName="p">{`Happiness, Joy:`}</strong>{` We value a joyful environment that promotes a happy and motivated community.`}</p>
    <h3 {...{
      "id": "parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#parameters",
        "aria-label": "parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parameters`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Maintains its status as a mid-sized IB world day school (not to exceed 600 students) that provides an international education for students aged 3 - 18`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Aligns school enrolment, facilities, finances, resources and capacity to ensure sustainability`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintains the international identity of the school whilst respecting Ukrainian culture`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Uses English as the main language of instruction, whilst demonstrating respect of mother tongue languages`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Approves and maintains programmes that promote the overall well-being of its students`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensures students' best interests are at the centre of all decision-making`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Enrols students and families, and employs staff who accept and exemplify the school’s mission, vision and core values`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Only accepts behaviour, from all community members, that ensures the safety, integrity, and dignity of all individuals`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Hires and retains qualified and flexible staff members who are committed to ongoing professional growth`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Expects direct parent involvement in the lives of their children at school`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "commitment-to-nondiscrimination",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#commitment-to-nondiscrimination",
        "aria-label": "commitment to nondiscrimination permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Commitment to Nondiscrimination`}</h3>
    <p>{`PSI is committed to providing an inclusive and welcoming environment for all members of its community - irrespective of race, religion, creed, colour, national origin, gender, gender expression, sexual orientation, age, disability or ancestry. The school does not tolerate physical, mental, sexual or cultural discrimination or harassment of any type.`}</p>
    <h3 {...{
      "id": "commitment-to-an-environmentally-friendly-campus",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#commitment-to-an-environmentally-friendly-campus",
        "aria-label": "commitment to an environmentally friendly campus permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Commitment to an Environmentally Friendly Campus`}</h3>
    <p>{`PSI recognises that its activities' impact upon the environment. The School is committed to continuously improving its performance in order to minimise that impact by implementing good practices that comply with national standards and international best practices. Guidelines for sustainability at all levels of the school, and its activities, are contained in the relevant procedural handbooks.`}</p>
    <h3 {...{
      "id": "definition-of-learning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#definition-of-learning",
        "aria-label": "definition of learning permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Definition of Learning`}</h3>
    <p>{`PSI is an IB World School that defines learning as a holistic, purposeful, learner-centred and inquiry-based process. At PSI, learners engage in a personalised journey of discovery, reflection and action, which is motivated by their passions and interests. Recognising that learning is limitless, individuals collaboratively build their knowledge, skills and understanding in academics, arts, athletics, and service, with the well-being of themselves and the wider global community in mind.`}</p>
    <h3 {...{
      "id": "ib-mission-statement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ib-mission-statement",
        "aria-label": "ib mission statement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IB Mission Statement`}</h3>
    <p>{`The International Baccalaureate aims to develop inquiring, knowledgeable and caring young people who help to create a better and more peaceful world through intercultural understanding and respect.`}</p>
    <p>{`To this end, the organisation works with schools, governments and international organisations to develop challenging programmes of international education and rigorous assessment. These programmes encourage students across the world to become active, compassionate and lifelong learners who understand that other people, with their differences, can also be right.`}</p>
    <h3 {...{
      "id": "the-ib-learner-profile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-ib-learner-profile",
        "aria-label": "the ib learner profile permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Ib Learner Profile`}</h3>
    <p>{`As a fully authorised IB World School, Pechersk School International follows the philosophy and guidelines laid out by the International Baccalaureate, including its commitment to international education. The IB Learner Profile runs across all three IB programmes and, ‘is central to the definition of what it means to be internationally-minded’. (`}<em parentName="p">{`IB Learner Profile Booklet`}</em>{`. March 2006).`}</p>
    <table>
    <colgroup>
        <col style={{
          "width": "20%"
        }} />
        <col style={{
          "width": "79%"
        }} />
    </colgroup>
    <tr className="header">
        <td>
            <strong>Inquirers</strong>
        </td>
        <td><p>We nurture our curiosity, developing skills for inquiry and research. We know how to learn independently and with others. We learn with enthusiasm and sustain our love of learning throughout life.</p></td>
    </tr>
    <tr className="odd">
        <td>
            <strong>Knowledgeable</strong>
        </td>
        <td><p>We develop and use conceptual understanding, exploring knowledge across a range of disciplines. We engage with issues and ideas that have local and global significance.</p></td>
    </tr>
    <tr className="header">
        <td>
            <strong>Thinkers</strong>
        </td>
        <td><p>We use critical and creative thinking skills to analyse and take responsible action on complex problems. We exercise initiative in making reasoned, ethical decisions.</p></td>
    </tr>
    <tr className="odd">
        <td>
            <strong>Communicators</strong>
        </td>
        <td><p>We express ourselves confidently and creatively in more than one language and in many ways. We collaborate effectively, listening carefully to the perspectives of other individuals and groups.</p></td>
    </tr>
    <tr className="header">
        <td>
            <strong>Principled</strong>
        </td>
        <td><p>We act with integrity and honesty, with a strong sense of fairness and justice, and with respect for the dignity and rights of people everywhere. We take responsibility for our actions and their consequences.</p></td>
    </tr>
    <tr className="odd">
        <td>
            <strong>Open-minded</strong>
        </td>
        <td><p>We critically appreciate our own cultures and personal histories, as well as the values and traditions of others. We seek and evaluate a range of points of view, and we are willing to grow from the experience.</p></td>
    </tr>
    <tr className="header">
        <td>
            <strong>Carers</strong>
        </td>
        <td><p>We show empathy, compassion and respect. We have a commitment to service, and we act to make a positive difference in the lives of others and the world around us.</p></td>
    </tr>
    <tr className="odd">
        <td>
            <strong>Risk-Takers</strong>
        </td>
        <td><p>We approach uncertainty with forethought and determination; we work independently and cooperatively to explore new ideas and innovative strategies. We are resourceful and resilient in the face of challenges and change.</p></td>
    </tr>
    <tr className="header">
        <td>
            <strong>Balanced</strong>
        </td>
        <td><p>We understand the importance of balancing different aspects of our lives - intellectual, physical and emotional - to achieve well-being for ourselves and others. We recognise our interdependence with other people and with the world in which we live.</p></td>
    </tr>
    <tr className="odd">
        <td>
            <strong>Reflective</strong>
        </td>
        <td><p>We thoughtfully consider the world and our own ideas and experience. We work to understand our strengths and weaknesses in order to support our learning and personal development.</p></td>
    </tr>
    </table>
    <h2 {...{
      "id": "definitions-and-philosophies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#definitions-and-philosophies",
        "aria-label": "definitions and philosophies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Definitions and Philosophies`}</h2>
    <p>{`As a school committed to following best practices in education, PSI staff and leadership are working on clearly defining key terms and rationales. For example, you will find the definition or learning on page 5 of this guide.`}</p>
    <p>{`As an offshoot of that process, each department / content area is developing its own K-12 philosophy statement. Here is one example.`}</p>
    <p><em parentName="p">{`The mathematics programme at PSI inspires students to discover the beauty and power of mathematics. In both Primary and Secondary School, students work both independently and cooperatively to become flexible, fluent and accurate in using multiple strategies and tools for solving mathematical problems and recognising patterns. At every grade, students develop their abilities to clearly communicate their thinking orally, in writing and using models, as they efficiently apply their mathematical understandings to find solutions to real-life problems. Learners are supported by teachers who use a differentiated approach to deliver inquiry-based, concept-driven instruction.`}</em></p>
    <p>{`As the School continues to develop these clarifying and guiding definitions and statements, we will continue to share them with the PSI community as a whole.`}</p>
    <h2 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h2>
    <p>{`Pechersk School International Kyiv (PSI) is a modern, comprehensive, co-educational day school serving students between the ages of 3 and 18 years of age (Early Childhood `}{`[`}{`EC`}{`]`}{` through Grade 12) from more than 40 countries. The name ‘Pechersk’ was taken from the area of Kyiv known historically as the city’s centre of learning, reflecting the discipline and integrity of the scholarship practiced at the famous `}<em parentName="p">{`Pecherska Lavra`}</em>{`, now a UNESCO World Heritage site.`}</p>
    <p>{`The school was started in 1995 by a group of parents from the diplomatic and business community, and is a not-for-profit entity. Enlisting the help of embassies, companies and individuals, parents opened the school with just 47 students. Since then, the school has grown to approximately 500 students. In August 2003, PSI moved to its current location at Viktora Zabily 7a, close to the centre of Kyiv on the right bank of the Dnipro River.`}</p>
    <p>{`Our EC – Grade 12 staff is made up of more than 60 teachers from nine different countries with the four largest national groups representing the USA, Canada, Ukraine, and UK. All hold teaching degrees and more than 70% hold a Masters Degree; four of our educators have doctorates.`}</p>
    <p>{`Admission is determined by the age of the student, previous school reports, an admissions screening procedure, and the school’s ability to provide a suitable programme within class size limitations.`}</p>
    <p>{`The average class size for all MYP (Grades 6 - 10) courses is 15; DP classes (Grades 11 and 12) have an average of 10 students. The preferred maximum class size is 20, allowing for significant personal attention. (Please note that class sizes vary based on subject area and size of year group.) An English as an Additional Language (EAL) programme is available for non-native English speaking students through Grade 12 to support their development as English language learners. The school has no special provision for children with significant learning disabilities, but does offer support to those with mild to moderate identified cognitive or learning disabilities. The Secondary School has one full time Learning Support teacher and two teaching assistants who serve students Grades 6 - 12.`}</p>
    <h3 {...{
      "id": "facilities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#facilities",
        "aria-label": "facilities permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Facilities`}</h3>
    <p>{`PSI Secondary School is contained in two of the three buildings on campus. Building A includes a full-service cafeteria; art, music, and drama classrooms; three science labs and a science classroom; a performing arts space; and a double gymnasium with a swimming pool underneath. Building C has classrooms on all three floors, as well as a Design Technology lab, a library, an IT office, and other administrative offices. There are approximately two acres of outdoor area, including a covered Astro Turf soccer pitch. We also use the pitch and running track at the Litsey School adjacent to PSI; these facilities were completed in 2018 and are shared by both schools.`}</p>
    <p>{`The site is completely enclosed with a gated entrance with access through electronically controlled turnstiles. The School is under 24-hour surveillance by security guards. PSI also leases tennis courts and other athletic facilities off campus to extend our physical education programme. The whole campus is equipped for wireless Internet.`}</p>
    <h2 {...{
      "id": "daily-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#daily-schedule",
        "aria-label": "daily schedule permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Daily Schedule`}</h2>
    <table style={{
      "width": "100%"
    }}>
    <colgroup>
        <col style={{
          "width": "15%"
        }} />
        <col style={{
          "width": "16%"
        }} />
        <col style={{
          "width": "18%"
        }} />
        <col style={{
          "width": "4%"
        }} />
        <col style={{
          "width": "15%"
        }} />
        <col style={{
          "width": "15%"
        }} />
        <col style={{
          "width": "15%"
        }} />
    </colgroup>
    <thead>
    <tr className="header">
        <td colSpan="7">
            <strong>Current Draft: Early community &amp; Split Lunches</strong>
        </td>
    </tr>
    <tr className="odd">
        <td colSpan="3">Middle School Timetable</td>
        <td></td>
        <td colSpan="3">High School Timetable</td>
    </tr>
    <tr className="header">
        <td colSpan="3">Regular Day witd Morning Advisory</td>
        <td></td>
        <td colSpan="3">Regular Day witd Morning Advisory</td>
    </tr>
    <tr className="odd">
        <td>Time</td>
        <td>Period</td>
        <td>Block</td>
        <td></td>
        <td>Time</td>
        <td>Period</td>
        <td>Block</td>
    </tr>
    <tr className="header">
        <td>8:15 - 8:55</td>
        <td>Period 1 (40)</td>
        <td rowSpan="2">
            <p>Block 1-2</p>
            <p>8:15 - 9:40 (85)</p>
        </td>
        <td></td>
        <td>8:15 - 8:55</td>
        <td>Period 1 (40)</td>
        <td rowSpan="2">
            <p>Block 1-2</p>
            <p>8:15 - 9:40 (85)</p>
        </td>
    </tr>
    <tr className="odd">
        <td>9:00 - 9:40</td>
        <td>Period 2 (40)</td>
        <td></td>
        <td>9:00 - 9:40</td>
        <td>Period 2 (40)</td>
    </tr>
    <tr className="header">
        <td>9:40 - 9:50</td>
        <td colSpan="2">Snack / Long Passing (10)</td>
        <td></td>
        <td>9:40 - 9:50</td>
        <td colSpan="2">Snack / Long Passing (10)</td>
    </tr>
    <tr className="odd">
        <td>9:50 - 10:10</td>
        <td colSpan="2">Community (20)</td>
        <td></td>
        <td>9:50 - 10:10</td>
        <td colSpan="2">Community (20)</td>
    </tr>
    <tr className="header">
        <td>10:15 - 10:55</td>
        <td>Period 3 (40)</td>
        <td>Period 3 (40)</td>
        <td></td>
        <td>10:15 - 10:55</td>
        <td>Period 3 (40)</td>
        <td rowSpan="2">
            <p>Block 3-4</p>
            <p>10:15 - 11:40 (85)</p>
        </td>
    </tr>
    <tr className="odd">
        <td>10:55 - 11:35</td>
        <td colSpan="2">LUNCH (40)</td>
        <td></td>
        <td>11:00 - 11:40</td>
        <td>Period 4 (40)</td>
    </tr>
    <tr className="header">
        <td>11:35 - 12:15</td>
        <td>Period 4 (40)</td>
        <td>Period 4 (40)</td>
        <td></td>
        <td>11:40 - 12:20</td>
        <td colSpan="2">LUNCH (40)</td>
    </tr>
    <tr className="odd">
        <td>12:20 - 13:00</td>
        <td>Period 5 (40)</td>
        <td rowSpan="2">
            <p>Block 5-6</p>
            <p>12:20 - 13:45 (85)</p>
        </td>
        <td></td>
        <td>12:20 - 13:00</td>
        <td>Period 5 (40)</td>
        <td rowSpan="2">
            <p>Block 5-6</p>
            <p>12:20 - 13:45 (85)</p>
        </td>
    </tr>
    <tr className="header">
        <td>13:05 - 13:45</td>
        <td>Period 6 (40)</td>
        <td></td>
        <td>13:05 - 13:45</td>
        <td>Period 6 (40)</td>
    </tr>
    <tr className="odd">
        <td>13:45 - 13:55</td>
        <td colSpan="2">Snack / Long Passing (10)</td>
        <td></td>
        <td>13:45 - 13:55</td>
        <td colSpan="2">Snack / Long Passing (10)</td>
    </tr>
    <tr className="header">
        <td>13:55 - 14:35</td>
        <td>Period 7 (40)</td>
        <td rowSpan="2">
            <p>Block 7-8</p>
            <p>13:55 - 15:20 (85)</p>
        </td>
        <td></td>
        <td>13:55 - 14:35</td>
        <td>Period 7 (40)</td>
        <td rowSpan="2">
            <p>Block 7-8</p>
            <p>13:55 - 15:20 (85)</p>
        </td>
    </tr>
    <tr className="odd">
        <td>14:40 - 15:20</td>
        <td>Period 8 (40)</td>
        <td></td>
        <td>14:40 - 15:20</td>
        <td>Period 8 (40)</td>
    </tr>
    </thead>
    <tbody></tbody>
    </table>
    <p>{`Extra Curricular Activities begin at 15:30 or 16:00, depending on the activity. Students should not arrive at school before 8:00 or stay after 17:00 unless they are part of a scheduled and supervised activity. Students are not permitted on campus on weekends unless this has been pre-arranged with school administration and includes adult supervision.`}</p>
    <h2 {...{
      "id": "the-six-day-cycle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-six-day-cycle",
        "aria-label": "the six day cycle permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Six Day Cycle`}</h2>
    <p>{`PSI operates on a six-day rotation of classes. For example, Week 1 would hold classes for Days A, B, C, D and E. Week 2 would have classes for Days F, A, B, C and D. Week 3 would run classes for Days E, F A, B and C. The days are always communicated in our Monday emails and are posted at entrance points to the School.`}</p>
    <h2 {...{
      "id": "advisories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#advisories",
        "aria-label": "advisories permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advisories`}</h2>
    <p>{`Our Secondary School Advisory Programme promotes a student-centred learning environment within a small group, enabling it to be responsive to the needs of all students.`}</p>
    <p>{`At PSI, we recognise the importance of social-emotional development in the holistic growth of our students.`}</p>
    <p>{`Through our Advisory Programme, we aim to foster meaningful relationships, to cultivate empathy, and to encourage a journey of self-discovery in a safe and supportive community. Our collective and individual well-being is enhanced as students and faculty engage authentically within their advisory group, crafting outcomes that are purposeful and personalised.`}</p>
    <p>{`Specifics of the programme:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Every student in Grades 6 - 12 is assigned to an advisory group with approximately 8-10 students and one teacher.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Advisory meets daily for 20 minutes daily between 2nd and 3rd periods.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Advisory lessons support the social-emotional development of students.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "exploratories-and-study-periods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#exploratories-and-study-periods",
        "aria-label": "exploratories and study periods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exploratories And Study Periods`}</h2>
    <p>{`Every MYP student has opportunities to take some non-graded classes. The programme is designed to allow students to explore areas of interest from the arts to physical activity, from languages to leadership. Throughout the 6-day cycle, students may have as many as four Exploratory opportunities. Each class lasts for one quarter and is non-graded.`}</p>
    <p>{`In addition, all Secondary students have at least two 40-minute study periods in the 6-day cycle. For MYP students, these are teacher-monitored sessions that allow students to work on upcoming homework assignments, work in small groups, or ask questions of teachers. DP students in Grade 11 and 12 are not usually teacher monitored, but they are encouraged to use the time to work on their academic subjects, meet with the counsellor regarding university applications, or speak with teachers.`}</p>
    <h2 {...{
      "id": "programme-organisation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#programme-organisation",
        "aria-label": "programme organisation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Programme Organisation`}</h2>
    <p>{`PSI is an International Baccalaureate (IB) World School, offering all three of the IB programmes – Primary Years Programme (PYP) in EC – Grade 5, Middle Years Programme (MYP) in Grades 6 - 10 and Diploma Programme (DP) in Grades 11 and 12. (Both MYP and DP will be explained in depth in this guide.)`}</p>
    <p>{`At High School level (Grades 9 – 12), students may follow one of three paths toward graduation.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`All students who successfully complete our high school program receive a PSI diploma, which is accredited by the Council for International Schools (CIS) in Europe and the New England Association of Schools and Colleges (NEASC) in the United States.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In addition, students may opt to earn IB certificates for some classes in Grades 11 and 12.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Finally, highly motivated and proficient students may choose to work toward the IB Diploma, which will be explained in the DP section of this guide.`}</p>
      </li>
    </ul>
    <p>{`Regardless of which path they choose, all students must meet the PSI standards for graduation, as outlined in the chart below.`}</p>
    <h2 {...{
      "id": "three-paths-to-graduation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#three-paths-to-graduation",
        "aria-label": "three paths to graduation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Three Paths To Graduation`}</h2>
    <Table fullWidth innerWidth={1000} mdxType="Table">
    <table>
        <colgroup>
            <col style={{
            "width": "15%"
          }} />
            <col style={{
            "width": "28%"
          }} />
            <col style={{
            "width": "28%"
          }} />
            <col style={{
            "width": "28%"
          }} />
        </colgroup>
        <thead>
        <tr className="header">
            <th></th>
            <th>
                <p>High School Graduation</p>
            </th>
            <th>
                <p>DP Certificates</p>
            </th>
            <th>
                <p>Full IB Diploma</p>
            </th>
        </tr>
        </thead>
        <tr className="odd">
            <td>
                <p><strong>What It Is</strong></p>
            </td>
            <td>
                <p>An American-style diploma that certifies that the student has completed the necessary school- based requirements</p>
            </td>
            <td>
                <p>Acknowledgement from the IB that a student has met the requirements of a specific course or courses</p>
            </td>
            <td>
                <p>Acknowledgement from the IB that a student has successfully completed the entire Diploma Programme</p>
            </td>
        </tr>
        <tr className="header">
            <td>
                <p><strong>What It Requires</strong></p>
            </td>
            <td>
                <ul>
                    <li>4 years of Language &amp; Literature</li>
                    <li>3 years of Maths</li>
                    <li>3 years of Individuals &amp; Societies</li>
                    <li>3 years of Science</li>
                    <li>2 years of Language Acquisition</li>
                    <li>1 year of Arts</li>
                    <li>1 year of Physical &amp; Health Education</li>
                    <li>1 year of Design / Design Technology</li>
                    <li>4 years of Electives*</li>
                    <li>4 years of Service Action / Creativity, Action &amp; Service</li>
                    <li>Electives are any courses taken in any subject area that exceed the required minimum.</li>
                </ul>
            </td>
            <td>
                <p>Successful completion of internal and external assessment for each selected DP course</p>
                <p>NOTE: In order to receive a full credit, students who fail the first semester of a course must successfully complete the second semester so that the overall grade is a 2.5 or higher. A student who fails a course in the second semester will be granted no credit for the course.</p>
            </td>
            <td>
                <ul>
                    <li>3 Higher Level courses</li>
                    <li>3 Standard Level courses</li>
                    <li>2 years of Theory of Knowledge</li>
                    <li>A 4000-word Extended Essay</li>
                    <li>Completion of Creativity, Activity and Service over an 18 month period and with required documentation</li>
                </ul>
            </td>
        </tr>
        <tr className="odd">
            <td>
                <p><strong>Best Suited For</strong></p>
            </td>
            <td>
                <ul>
                    <li>Students heading to North American universities</li>
                    <li>Students who are heading to military service, technical college or the workplace</li>
                </ul>
            </td>
            <td>
                <ul>
                    <li>Students who do not need 3 Higher Level courses but who want to boost their college applications</li>
                    <li>Students who have interests in specific subject areas</li>
                </ul>
            </td>
            <td>
                <ul>
                    <li>Students who have strong academic skills and high motivation</li>
                </ul>
            </td>
        </tr>
        <tbody></tbody>
    </table>
    </Table>
    <p>{`It is important to note that students on any track can apply for top tier universities; each tier provides different options for acceptances to colleges around the world.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      